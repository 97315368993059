// material-ui
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project import
import MainCard from 'components/MainCard';

// assets
import avatar from 'assets/images/users/avatar-group.png';
import AnimateButton from 'components/@extended/AnimateButton';

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

export default function NavCard() {
  return (
    <MainCard sx={{ bgcolor: 'grey.50', m: 3 }}>
      <Stack alignItems="center" spacing={2.5}>
        <CardMedia component="img" image={avatar} />
        <Stack alignItems="center">
          <Typography variant="h5">Need Help?</Typography>
          <Typography variant="h6" color="secondary" align="center">
            Contact your PM or email us
          </Typography>
        </Stack>
        <AnimateButton>
          <Button variant="shadow" size="small" href="mailto:support@hackerest.com">
            Email Us
          </Button>
        </AnimateButton>
      </Stack>
    </MainCard>
  );
}
