import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import v1 from 'api/v1';
import { Button, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

const formatNumber = (credit: number) => {
  if (credit >= 1000000) {
    return (credit / 1000000).toFixed(1) + 'M';
  } else if (credit >= 1000) {
    return (credit / 1000).toFixed(1) + 'K';
  } else {
    return credit;
  }
};

export default function BuyCredits() {
  const { data } = v1.getCompany();
  const company = data?.data.data || {};
  const theme = useTheme();
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title="Buy More Credits">
        <motion.div
          animate={{
            boxShadow: ['0 0 0 0px rgba(0, 0, 0, 0.2)', '0 0 0 10px rgba(0, 0, 0, 0)'],
          }}
          transition={{
            repeat: Infinity,
            repeatDelay: 1,
            duration: 1,
          }}
          style={{
            display: 'inline-block', // Ensures the animation applies to the element
            borderRadius: '1px', // Apply border-radius to the element itself
          }}
        >
          <Button
            color="secondary"
            variant="outlined" // Or "contained" if you want a solid button
            sx={{
              color: 'text.primary',
              bgcolor: 'transparent',
            }}
            aria-label="buy credits"
            component={Link} // Use Link component for routing
            to="/credits" // Set the path you want to navigate to
          >
            <span style={{ marginRight: 8 }}>{formatNumber(company?.credit)}</span>
            <FontAwesomeIcon icon={faCoins} color={theme.palette['text'].primary || '#333'} />
          </Button>
        </motion.div>
      </Tooltip>
    </Box>
  );
}
