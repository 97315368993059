import { Box, Tooltip, useTheme } from '@mui/material';
import { ThemeMode } from 'config';
import useConfig from 'hooks/useConfig';
import { DarkModeSwitch as DarkModeSwitchLib } from 'react-toggle-dark-mode';
import IconButton from 'components/@extended/IconButton';

const DarkModeSwitch = () => {
  const { mode, onChangeMode } = useConfig();
  const theme = useTheme();

  const isDarkMode = mode === ThemeMode.DARK;

  const toggleDarkMode = () => {
    onChangeMode(isDarkMode ? ThemeMode.LIGHT : ThemeMode.DARK);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title={isDarkMode ? 'Light Mode' : 'Dark Mode'}>
        <IconButton
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: 'transparent' }}
          aria-label="fullscreen toggler"
          onClick={toggleDarkMode}
        >
          <DarkModeSwitchLib
            checked={isDarkMode}
            onChange={() => {}}
            size={16}
            sunColor={theme.palette['text'].primary || '#333'}
            moonColor={theme.palette['text'].primary || '#333'}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default DarkModeSwitch;
