import React from 'react';
// eslint-disable-next-line no-restricted-imports
import CopyToClipboard from 'react-copy-to-clipboard';
import { openSnackbar } from 'api/snackbar';
import { SnackbarProps } from 'types/snackbar';

// Assuming openSnackbar and SnackbarProps are accessible in this context
const CopyToClipboard2 = ({ text, onCopy, children }: any) => {
  const realOnCopy = () => {
    if (onCopy) onCopy();
    else {
      openSnackbar({
        open: true,
        message: 'Copied Successfully',
        variant: 'alert',
        alert: {
          color: 'success',
        },
      } as SnackbarProps);
    }
  };

  return (
    <CopyToClipboard text={text || ''} onCopy={realOnCopy}>
      {children}
    </CopyToClipboard>
  );
};

export default CopyToClipboard2;
