// material-ui
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

// project import
import Profile from './Profile';
import FullScreen from './FullScreen';
import Customization from './Customization';
import DarkModeSwitch from './DarkModeSwitch';

import useConfig from 'hooks/useConfig';
import { MenuOrientation } from 'config';
import DrawerHeader from 'layout/Dashboard/Drawer/DrawerHeader';
import BuyCredits from './BuyCredits';
import useAuth from 'hooks/useAuth';

// ==============================|| HEADER - CONTENT ||============================== //

export default function HeaderContent() {
  const { menuOrientation } = useConfig();
  const { user } = useAuth();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isLocalhost = ['localhost', '127.0.0.1'].includes(window.location.hostname);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {/* {!downLG && <Search />} */}
      {!downLG && <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />}
      {/* {!downLG && megaMenu} */}
      {/* {!downLG && localization} */}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* <Notification /> */}
      {/* <Message /> */}

      {!downLG && <DarkModeSwitch />}
      {!downLG && <FullScreen />}
      {isLocalhost && <Customization />}
      {!downLG && user?.role === 'customer' && <BuyCredits />}
      <Profile />
      {/* downLG && <MobileSection /> */}
    </>
  );
}
