import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Outlet } from 'react-router-dom';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import config from 'config';

// ==============================|| LAYOUT - AUTH ||============================== //

export default function AuthLayout() {
  return (
    <GuestGuard>
      <GoogleReCaptchaProvider reCaptchaKey={config.googleRecaptchaKeyV3}>
        <Outlet />
      </GoogleReCaptchaProvider>
    </GuestGuard>
  );
}
